import React from 'react';
import {Icon} from '../../Utils/Icon';

function CampaignRow({ dateRange, adPlatform, runtime, kpis, optimalValue, budget, aim, creative }) {
  return (
    <div className="flex flex-wrap  justify-start items-center">
      <div className="grid grid-cols-6 gap-2">
        <div className="flex pt-40 flex-col col-span-1 gap-2">
          <span className="text-sm font-bold">Runtime</span>
          <span className="text-sm font-medium">{dateRange}</span>
          <span className="text-sm font-medium">{runtime}</span>
        </div>
        <div className="relative">
          <div className="absolute top-40 left-0 w-4 h-4 bg-gray-400 rounded-full"></div>
          <div className=" ml-1.5 h-full w-1 bg-gray-400"></div>
        </div>

        <div className="flex pt-40 col-span-2 flex-col gap-2">
          <span className="text-md font-bold">{adPlatform}</span>
          <span className="text-sm">{aim}</span>
          <div className="flex flex-col mt-5 gap-2">
            <span className="text-sm font-bold">Creative</span>
            <span className="text-sm">{creative}</span>
          </div>
          <div className="flex flex-col mt-5 gap-2">
            <span className="text-sm font-bold">KPIs</span>
            <span className="text-sm">{kpis}</span>
          </div>
          <div className="flex flex-col mt-5 gap-2">
            <span className="text-sm font-bold">Optimal values</span>
            <span className="text-sm">{optimalValue}</span>
          </div>
          <div className="flex flex-col mt-5 gap-2">
            <span className="text-sm font-bold">Budget</span>
            <span className="text-sm">{budget}</span>
          </div>

          
        </div>
        <div className="col-span-2 flex pt-40 flex-row justify-end">
           <div className="">
              <button className="bg-sky-500 text-white m-1 py-2 px-4 rounded-lg hover:bg-sky-600"><Icon name={"Check"} className=""/></button>
              <button className="bg-sky-500 text-white m-1 py-2 px-4 rounded-lg hover:bg-sky-600"><Icon name={"X"} className=""/></button>
              <button className="bg-sky-500 text-white m-1  py-2 px-4 rounded-lg hover:bg-sky-600"><Icon name={"Edit"} className=""/></button>
              </div>
          </div>  
      </div>

    </div>

  );
}

export default CampaignRow;
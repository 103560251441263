import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import RequestPasswordReset from './components/Pages/Auth/RequestPasswordReset';
import ResetPassword from './components/Pages/Auth/ResetPassword';
import SignIn from './components/Pages/Auth/SignIn';
import Showcase from './components/Pages/Showcase/Showcase';
import SignUp from './components/Pages/Auth/SignUp';
import Landingpage from './components/Pages/Landingpages/Landingpage';
import ProtectedRoute from './components/Utils/ProtectedRoute';
import './index.css';


const color = process.env.REACT_APP_MAIN_COLOR


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <BrowserRouter>
      <Routes>
        <Route index element={
          <Landingpage/>
        }/>
        <Route path={'pro'} element={
          <ProtectedRoute>
            <App/>
          </ProtectedRoute>
        }/>
        <Route path={'showcase'} element={
          <ProtectedRoute>
            <Showcase color={color}/>
          </ProtectedRoute>}/>
        <Route path={'signin'} element={<SignIn color={color}/>}/>
        <Route path={'signup'} element={<SignUp color={color}/>}/>
        <Route path={'request-password'} element={<RequestPasswordReset color={color}/>}/>
        <Route path={'reset-password'} element={<ResetPassword color={color}/>}/>
      </Routes>
    </BrowserRouter>

  
);
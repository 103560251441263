import { Icon } from "../../Utils/Icon";

export default function TextInput({type,
                                   label, 
                                   placeholder, 
                                   color,
                                   hint, 
                                   icon,
                                   value,
                                   setValue,
                                   isError=false,
                                   errorMessage=undefined,
                                   disabled=false, 
                                   checkOnValueChange=false}) {

    //const [value, setValue] = useState()
    //const [regexExp, setRegexExp] = useState()
    
    const disabledString = disabled ? "disabled" : ""

    // define different input check functions
    //useEffect(()=>{
    //    
    //    if (type === "email"){
    //        const reg  = /.+@.+\.[A-Za-z]+$/
    //        setErrorMessage("Incorrect Email, please check your input.")
    //        setRegexExp(reg)
    //    }else{
    //        
    //    }
    //                               
    //}, [type])
   
    
    // do checks on input value
    //useEffect(()=>{
    //    // check if input matches regExp
    //    if (checkOnValueChange){
    //       if (regexExp !== undefined){
    //            // Use regex to validate input
    //            const isValid = regexExp.test(value)
    //            if (isValid){
    //                setIsError(false)
    //                setStateColor(color)  
    //            }else{
    //                setIsError(true)
    //                setStateColor("red")
    //            }
    //        }    
    //    }
    //}, [value, color, regexExp, checkOnValueChange])
    
   

    return (
        <div>
            <label htmlFor={label.toLowerCase()} className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            
            <div className="relative mt-1 rounded-md shadow-sm">
                {
                    icon !== undefined ? 
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <Icon name={icon} className="text-gray-400 -ml-0.5 mr-2 h-4 w-4"/>
                        </div>
                    :
                        ""
                }
               
                <input
                    type={type}
                    name={label.toLowerCase()}
                    id={label.toLowerCase()}
                    value={value}
                    onChange={(e)=>setValue(e.target.value)}
                    className={` ${icon !== undefined ? "pl-8" : ""} block w-full rounded-md border-${isError ? "red": "gray"}-300  ${disabled ? "disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-50 disabled:text-gray-500": ""} shadow-sm focus:border-${color}-500 focus:ring-${color}-500 sm:text-sm`}
                    placeholder={placeholder}
                    disabled = {disabledString}
                    aria-invalid="true"
                    aria-describedby="email-error"
                />
                {
                    isError ? 
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                            <Icon name="AlertOctagon" className="h-5 w-5 text-red-500" aria-hidden="true"/>
                        </div>
                        :
                        ""
                }
            </div>
            {
                hint ?
                    <p className="mt-2 text-sm text-gray-500" id="email-description">
                        {hint}
                    </p> 
                :
                    ""
            }
            {
                isError && (errorMessage !== "")  ?
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                        {errorMessage}
                    </p>
                :
                    ""
            }
            
        </div>
    )
  }


export default function SingleCheckbox({label, 
                                        color,
                                        value,
                                        setValue,
                                        isError=false}) {

   
   
    return (
        <div className="flex items-center justify-between">
            <div className="flex items-center">
            <input
                id="checkbox"
                name="checkbox"
                type="checkbox"
                defaultChecked={value}
                onChange={() => setValue(!value)}
                className={`h-4 w-4 rounded border-gray-300 ${isError ? `text-red-600 focus:ring-red-500`: `text-${color}-600 focus:ring-${color}-500`}`}
            />
            <label onClick={() => {}} htmlFor="accept-terms" className={`ml-2 cursor-pointer font-medium ${isError ? `text-red-600 hover:text-red-900` : `text-${color}-600 hover:text-${color}-900`} block text-sm`}>
                {label}
            </label>
            </div>
        </div>
    )
  }


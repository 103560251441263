import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { useNavigate } from "react-router-dom"
const navigation = [


  //{ name: 'Product', href: '#' },
]

export default function Main({color="sky",
                              logo="https://tailwindui.com/img/logos/mark.svg?color=blue&shade=500",
                              mainImage="https://media.discordapp.net/attachments/1075506979138707516/1088842968812757063/Jochen_Design_an_eye-catching_and_creative_illustration_that_vi_79bb52f7-95e1-4b1b-a0f5-c135cfc45628.png?width=1200&height=800"}) {
  
  const navigate = useNavigate()
  return (
    <div className="bg-gray-50 h-screen">
      <div className="relative overflow-hidden">
        <div className="absolute inset-y-0 h-full w-full" aria-hidden="true">
          <div className="relative h-full">
            <svg
              className="absolute right-full translate-y-1/3 translate-x-1/4 transform sm:translate-x-1/2 md:translate-y-1/2 lg:translate-x-full"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="e229dbec-10e9-49ee-8ec3-0286ca089edf"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)" />
            </svg>
            <svg
              className="absolute left-full -translate-y-3/4 -translate-x-1/4 transform sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="d2a68204-c383-44b1-b99f-42ccff4e5365"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={784} fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)" />
            </svg>
          </div>
        </div>

        <div className="relative pt-6 pb-16 sm:pb-24">
          <Popover>
            <div className="mx-auto max-w-7xl px-6">
              <nav className="relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
                <div className="flex flex-1 items-center md:absolute md:inset-y-0 md:left-0">
                  <div className="flex w-full items-center justify-between md:w-auto">
                    <div className="flex flex-row justify-center items-center gap-2">
                      <a href="#">
                        <span className="sr-only">Your Company</span>
                        <img
                          className="h-8 w-auto sm:h-10"
                          src={logo}
                          alt=""
                        />
                      </a>
                      <div className="text-xl font-bold">
                        Autopilot
                      </div>
                    </div>
                    <div className="-mr-2 flex items-center md:hidden">
                      <Popover.Button className={`inline-flex items-center justify-center rounded-md bg-gray-50 p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-${color}-500`}>
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="hidden md:flex md:space-x-10">
                  {navigation.map((item) => (
                    <a key={item.name} href={item.href} className="font-medium text-gray-500 hover:text-gray-900">
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="hidden md:absolute md:inset-y-0 md:right-0 md:flex md:items-center md:justify-end">
                  
                 <span className="inline-flex mr-5 rounded-md shadow">
                    <div
                      onClick={() => navigate("/signup")}
                      className={`cursor-pointer inline-flex items-center rounded-md border border-transparent bg-white px-4 py-2 text-base font-medium text-${color}-600 hover:text-${color}-500`}
                    >
                      Sign-up
                    </div>
                  </span>
                  <span className="inline-flex rounded-md shadow">
                    <div
                      onClick={() => navigate("/signin")}
                      className={`cursor-pointer inline-flex items-center rounded-md border border-transparent bg-${color}-600 px-4 py-2 text-base font-medium text-white  hover:text-gray-200`}
                    >
                      Sign-in
                    </div>
                  </span>

                 
                </div>
              </nav>
            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
              >
                <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
                  <div className="flex items-center justify-between px-5 pt-4">
                    <div>
                      <img
                        className="h-8 w-auto"
                        src={logo}
                        alt=""
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className={`inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-${color}-500`}>
                        <span className="sr-only">Close main menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="space-y-1 px-2 pt-2 pb-3">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div
                    onClick={() => navigate("/signup")}
                    className={`block cursor-pointer w-full bg-gray-50 px-5 py-3 text-center font-medium text-${color}-600 hover:bg-gray-100 hover:text-${color}-700`}
                  >
                    Sign up
                  </div>
                  <div
                    onClick={() => navigate("/signIN")}
                    className={`block cursor-pointer w-full bg-gray-50 px-5 py-3 text-center font-medium text-${color}-600 hover:bg-gray-100 hover:text-${color}-700`}
                  >
                    Log in
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-24">
            <div className="text-center">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                <span className="">Take your marketing to </span>
                <span className={` text-${color}-600`}>new heights </span>
                <span className="">with Autopilot</span>
              </h1>
              <p className="mx-auto mt-3 max-w-md text-base text-gray-500 sm:text-lg md:mt-5 md:max-w-3xl md:text-xl">
              Autopilot empowers small businesses to create and execute effective communication strategies with ease.
              </p>
            </div>
              <div className="w-full flex flex-row justify-center items-center mt-10  ">

                <span className="inline-flex rounded-md shadow">
                        <a
                          href="/showcase"
                          className={`inline-flex items-center rounded-md border border-transparent bg-${color}-600 px-4 py-2 text-base font-medium text-white hover:text-white-500`}
                        >
                          Get started
                        </a>
                </span>
              </div>
          </div>
        </div>

        <div className="relative">
          <div className="absolute inset-0 flex flex-col" aria-hidden="true">
            <div className="flex-1" />
            <div className="w-full flex-1" />
          </div>
          <div className="mx-auto flex justify-center max-w-7xl px-6">
            <img
              className="relative rounded-lg shadow-lg"
              src={mainImage}
              alt="App screenshot"
            />
          </div>
        </div>
      </div>
    
    </div>
  )
}

import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import TextInput from "../../Inputs/TextInput/TextInput"
import supabase from "../../Utils/Supabase/Supabase"
import CampaignRow from "./CampaignRow"
import { Fragment } from 'react'

import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'


const navigation = [


    //{ name: 'Product', href: '#' },

  ]


export default function Showcase({color = "sky", 
                               image = "https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80",
                               logo  = "https://tailwindui.com/img/logos/mark.svg?color=blue&shade=400"
                            }) 
                            {
        const [companyInfo, setCompanyInfo] = useState('');
        const [marketingAim, setMarketingAim] = useState('');
        const [budget, setBudget] = useState('');
        const [startDate, setStartDate] = useState('');
        const [endDate, setEndDate] = useState('');
        const [time, setTime] = useState('');
        const [plan, setPlan] = useState(null);
        const [showResults, setShowResults] = useState(false);
        const [loading, setLoading] = useState(false);
        const navigate = useNavigate()
   
        const signOutUser = async () => {


          const { error } = await supabase.auth.signOut()
          if (error) console.log('Error signing out:', error.message)
          navigate('/')
        }

        useEffect(() => {
            if (plan) {
                
                setLoading(false);
            }
        }, [plan]);

        

        const handleTimeChange = (event) => {
            setTime(event.target.value);
        };

        const handleCompanyInfoChange = (event) => {
            setCompanyInfo(event.target.value);
        };
        
        const handleMarketingAimChange = (event) => {
            setMarketingAim(event.target.value);
        };
        
        const handleBudgetChange = (event) => {
            setBudget(event.target.value);
        };
        
        const handleStartDateChange = (event) => {
            setStartDate(event.target.value);
        };
        
        const handleEndDateChange = (event) => {
            setEndDate(event.target.value);
        };
        
       

          function extractTableInfo(tableString) {
            const split = tableString.split('|');

            // Remove the first and last element of the array
            split.shift();
            split.pop();

            // Join the string back together
            const joined = split.join('|');
            const newStrong = "| " + joined + " |";
            // Log
            console.log(newStrong);


            const lines = newStrong.trim().split('\n');
            const headers = lines[0].trim().split('|').map(h => h.trim());
            const rows = lines.slice(2).map(line => {
              const values = line.trim().split('|').map(v => v.trim());
              return headers.reduce((obj, header, index) => {
                obj[header] = values[index];
                return obj;
              }, {});
            });
            return rows;
          }

        const handleSubmit = (event)  => {
          if (loading == false) {

            setLoading(true);
            setShowResults(true);
            event.preventDefault();
            console.log('Submitted:', companyInfo, marketingAim, budget, startDate, endDate);
            const string = `${companyInfo}
${marketingAim}
Could you create a plan for our communication. The plan should include, when we should you which ad plattform, the runtime, expexted KPIs, optimal value for each KPI, how much we should spend, what aim we try to achive and what creative we should use. Please create a timeline starting from the 01.04.2023 lasting for ${time} months. Make it as a table. Our Budget is ${budget}€
Use the following table format: | Date| Platform| Runtime| Expected KPIs| Optimal Value| Budget | Aim| Creative|`;

            console.log(string);

            supabase.functions.invoke('marketing-plan', {
              body:JSON.stringify( {
                "companyInfo": companyInfo,
                "marketingAim": marketingAim,
                "time": time,
                "budget": budget,
              }),
            }).then(r => {

              const tableData = extractTableInfo(r.data.message)
              console.log(tableData);
              setPlan(tableData);
            })
            
          }
        };
    
      return (
        <div>
            <Popover>
            <div className="mx-auto mt-5 max-w-7xl px-6">
              <nav className="relative flex items-center justify-between sm:h-10 md:justify-center" aria-label="Global">
                <div className="flex flex-1 items-center md:absolute md:inset-y-0 md:left-0">
                  <div className="flex w-full items-center justify-between md:w-auto">
                    <div className="flex flex-row justify-center items-center gap-2">
                      <a href="#">
                        <span className="sr-only">Your Company</span>
                        <img
                          className="h-8 w-auto sm:h-10"
                          src={logo}
                          alt=""
                        />
                      </a>
                      <div className="text-xl font-bold">
                        Autopilot
                      </div>
                    </div>
                    <div className="-mr-2 flex items-center md:hidden">
                      <Popover.Button className={`inline-flex items-center justify-center rounded-md bg-gray-50 p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-${color}-500`}>
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
                <div className="hidden md:flex md:space-x-10">
                  {navigation.map((item) => (
                    <a key={item.name} href={item.href} className="font-medium text-gray-500 hover:text-gray-900">
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="hidden md:absolute md:inset-y-0 md:right-0 md:flex md:items-center md:justify-end">
                  <span className="inline-flex rounded-md shadow">
                    <div
                      onClick={() => { signOutUser() }}
    
                      
                      className={`cursor-pointer inline-flex items-center rounded-md border border-transparent bg-white px-4 py-2 text-base font-medium text-${color}-600 hover:text-${color}-500`}
                    >
                      Sign out
                    </div>
                  </span>
                </div>
              </nav>
            </div>

            <Transition
              as={Fragment}
              enter="duration-150 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
              >
                <div className="overflow-hidden rounded-lg bg-white shadow-md ring-1 ring-black ring-opacity-5">
                  <div className="flex items-center justify-between px-5 pt-4">
                    <div>
                      <img
                        className="h-8 w-auto"
                        src={logo}
                        alt=""
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className={`inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-${color}-500`}>
                        <span className="sr-only">Close main menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="space-y-1 px-2 pt-2 pb-3">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="block rounded-md px-3 py-2 text-base font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <a
                    href="#"
                    className={`block w-full bg-gray-50 px-5 py-3 text-center font-medium text-${color}-600 hover:bg-gray-100 hover:text-${color}-700`}
                  >
                    Log in
                  </a>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

        <div className="bg-sky min-h-screen flex flex-col gap-5  justify-center items-center">
          <form onSubmit={handleSubmit} className="max-w-4xl w-full p-6 bg-white rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold mb-4">Enter Company Info and Campaign Aim</h2>
            <div className="mb-4">
              <label htmlFor="company-info" className="block font-medium mb-2">Company Info:</label>
              <textarea 
                id="company-info" 
                placeholder="We are a small startup that devleops a SaaS service for..."
                className="w-full border border-gray-300 focus:border-sky-400 rounded-lg p-2" 
                value={companyInfo} 
                onChange={(e) => setCompanyInfo(e.target.value)} 
                rows="5"
              />
            </div>
            <div className="mb-6">
              <label htmlFor="campaign-aim" className="block font-medium mb-2">Campaign Aim:</label>
              <textarea 
                id="campaign-aim" 
                placeholder="Now we want to get as many people..."
                className="w-full border border-gray-300 focus:border-sky-400 rounded-lg p-2" 
                value={marketingAim} 
                onChange={(e) => setMarketingAim(e.target.value)} 
                rows="5"
              />
            </div>
            <div className="mb-4">
            <label className="block font-medium mb-2" htmlFor="budget-input">
                Budget (EUR)
            </label>
            <input
                className="w-full border border-gray-300 focus:border-sky-400 rounded-lg p-2"
                id="budget-input"
                type="text"
                placeholder=""
                value={budget}
                onChange={handleBudgetChange}
            />
            </div>
            <div className="mb-6">
                <label htmlFor="months-select" className="block text-gray-700 font-bold mb-2 w-full ">
                Select a duration:
                </label>
                <select
                value={time}
                onChange={handleTimeChange}
                className="w-full sm:w-auto shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="months-select"
                >
                <option value="1">1 Month</option>
                <option value="2">2 Months</option>
                <option value="3">3 Months</option>
                <option value="4">4 Months</option>
                <option value="5">5 Months</option>
                <option value="6">6 Months</option>
                </select>
            </div>
            
            <button type="submit" className={`bg-${color}-500 text-white  py-2 px-4 rounded-lg hover:bg-${color}-600`}>Submit</button>
          </form>
          {showResults? 

                

                <div className="max-w-4xl w-full p-6 bg-white rounded-lg shadow-lg">
                    <div className="text-2xl mb-10 font-bold">
                        Your Strategy
                    </div>
                    {loading ? 
                            <div role="status">
                            <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div> 
                    :
                            <div>
                                {plan.map((row, index) => { 
                                    if (row["Platform"] !== "" || row["Platform"] !== undefined)
                                    {
                                        return( <CampaignRow
                                            dateRange={row["Date"]}
                                            adPlatform={row["Platform"]}
                                            runtime={row["Runtime"]}
                                            kpis={row["Expected KPIs"]}
                                            optimalValue={row["Optimal Value"]}
                                            budget={row["Budget"]}
                                            aim={row["Aim"]}
                                            creative={row["Creative"]}
                                />)
                                    }
                                    
                                    })
                                        }
                            </div>
                    }
                   
                </div>
                
            : 
                null}

        </div>

        </div>
      );
  }

import Header from "./Header";
import Main from "./Main";

export default function Landingpage () {


    return (    <div className="bg-gray-50">
                
                    <Main header1="One place." 
                          header2="All information."
                          cta1="Get started"
                          cta2="Learn more" />

                </div>)
}
import { useState } from "react";
import { Navigate } from "react-router-dom"
import supabase from "./Supabase/Supabase";
import { LoadingScreen } from "./LoadingScreen";




const ProtectedRoute = ({children}) => {

    const [render, setRender] = useState(<LoadingScreen />)

    const getUserData = async () => {
        const { data: { user } } = await supabase.auth.getUser()
        return user
    }
    getUserData().then((user) => {setRender(user ? children : <Navigate to={"/signin"}/>)})

    return render
};

export default ProtectedRoute;